.fadeInCard {
    opacity: 0;
    animation: fadeIn ease 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
  
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  